import { AuthService, FetchConfig }            from 'aurelia-auth';
import { Aurelia, bindable, inject, PLATFORM } from 'aurelia-framework';
import { BaseViewModel }                       from 'base-view-model';
import { AureliaAuthConfig }                   from 'resources/configs/aurelia-auth-config';
import { AppContainer }                        from 'resources/services/app-container';
import environment                             from '../config/environment.json';

@inject(Aurelia, AppContainer, AuthService, FetchConfig)
export class Login extends BaseViewModel {

    /**
     * Form alert
     *
     * @type {{}}
     */
    @bindable alert = {};

    /**
     * Email field
     *
     * @type {string}
     */
    email = '';

    /**
     * Password field
     *
     * @type {string}
     */
    password = '';

    /**
     * Auth providers
     *
     * @type {Array}
     */
    authProviders = [];

    /**
     * Constructor
     *
     * @param aurelia
     * @param authService
     * @param appContainer
     * @param fetchConfig
     */
    constructor(aurelia, appContainer, authService, fetchConfig) {
        super(appContainer);

        this.aurelia     = aurelia;
        this.auth        = authService;
        this.fetchConfig = fetchConfig;
        this.environment = environment;

        Object.keys(AureliaAuthConfig.providers).forEach((provider) => this.authProviders.push(AureliaAuthConfig.providers[provider]));
    }

    /**
     * Handles activate event
     *
     * @param params
     */
    activate(params) {
        super.activate(params);

        this.fetchConfig.configure();

        // configures aurelia-auth fetch client to include application locale
        this.fetchConfig.httpClient.configure(config => {
            config.withDefaults({
                headers: {
                    'Application-Locale': this.appContainer.i18n.getLocale(),
                },
            });
        });
    }

    /**
     * Runs after view is attached
     */
    attached() {
        document.body.className = 'login-container';
    }

    /**
     * Handles form submission
     */
    login() {
        this.alert = {};

        if (this.validate()) {
            return this.auth.login(this.email, this.password)
                .then(() => {
                    this.resetForm();

                    this.aurelia.setRoot(PLATFORM.moduleName('app'));
                })
                .catch((response) => {
                    response.json().then((jsonResponse) => {
                        this.password = '';

                        this.alert = this.alertMessage(jsonResponse.status, jsonResponse.message);
                    });
                });
        }
    }

    /**
     * Performs a OAuth login
     *
     * @param provider
     *
     * @returns {*}
     */
    oauthLogin(provider) {
        this.alert = {};

        return this.auth
            .authenticate(provider)
            .then(() => {
                this.resetForm();
                this.aurelia.setRoot('app');
            })
            .catch((error) => error.json().then((response) => this.alert = this.alertMessage(response.status, response.message)));
    }

    /**
     * Validates login form
     *
     * TODO CHECK THIS OUT!!!!!
     *
     * @returns {boolean}
     */
    validate() {
        return this.email.length > 0 && this.password.length > 0;
    }

    /**
     * Navigates to the password reset request page
     */
    navigateToPasswordReset() {
        this.appContainer.router.navigateToRoute('password-resets.request');
    }

    /**
     * Resets login form
     */
    resetForm() {
        this.email    = '';
        this.password = '';
    }

}
